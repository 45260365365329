@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply relative bg-whiten  text-base font-normal text-body;
  }
}

.ui-pagination > * {
  @apply min-w-[32px] h-8 rounded items-center justify-center flex px-2 text-sm font-medium
      hover:bg-black/5
      dark:hover:bg-white/5
  
      disabled:pointer-events-none disabled:opacity-50
  
      data-[active]:bg-primary/90 data-[active]:text-white
      data-[active]:hover:bg-primary/80
      data-[active]:dark:hover:bg-primary/70;
}
.container {
  @apply px-2 md:px-8 max-w-[85rem] lg:px-20 xl:px-20 mx-auto;
}
.paddings {
  @apply py-12.5 md:py-20 lg:py-32;
}
.paddings2 {
  @apply p-4 md:p-6 2xl:p-10;
}
.ltr {
  direction: ltr !important;
}
.rtl {
  direction: rtl !important;
}

.ui-pagination {
  @apply flex;
}
.ui-pagination > * > svg {
  @apply -mx-2;
}

input[type="time"]::-webkit-clear-button {
  -webkit-appearance: none !important; 
  -moz-appearance: none !important;
  appearance: none !important;
  margin: 0 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  margin: 0 !important;
}
input[type=time]::-webkit-datetime-edit-ampm-field {
  display: none;
}

.tooltip {
  @apply relative inline-block cursor-pointer;
}

.tooltip .tooltiptext {
  @apply invisible w-32 bg-primary/70 text-white text-center rounded py-1 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 mb-2 opacity-0 transition-opacity duration-200;
}

.tooltip:hover .tooltiptext {
  @apply visible opacity-100;
}